import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import Footer from '../components/footer';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`fetchDailyUsedItems`}</h1>
    <p>{`This page lists everything that I use in my day to day life as a developer. I primarily use a Windows + Manjaro machine,
but most of the Software listed here is Windows specific! Manjaro has stuff I like built-in except the cross-platform stuff listed below.
If you are inspired to make your own `}<inlineCode parentName="p">{`/uses`}</inlineCode>{` page, consider
adding it to `}<a parentName="p" {...{
        "href": "https://uses.tech/"
      }}>{`uses.tech`}</a>{` by `}<a parentName="p" {...{
        "href": "https://twitter.com/wesbos"
      }}>{`Wes Bos`}</a>{`!`}</p>
    <h2>{`fetchEditorDetails`}</h2>
    <ul>
      <li parentName="ul">{`My Editor of choice is `}<a parentName="li" {...{
          "href": "https://code.visualstudio.com/"
        }}>{`VS Code`}</a>{`.`}</li>
      <li parentName="ul">{`Theme: `}<a parentName="li" {...{
          "href": "https://marketplace.visualstudio.com/items?itemName=GitHub.github-vscode-theme"
        }}>{`GitHub Dark`}</a>{`, Occasionally switch to `}<a parentName="li" {...{
          "href": "https://horizontheme.netlify.app/"
        }}>{`Horizon`}</a>{`.`}</li>
      <li parentName="ul">{`Font: `}<a parentName="li" {...{
          "href": "https://www.jetbrains.com/lp/mono/"
        }}>{`JetBrains Mono`}</a>{` for Editor, `}<a parentName="li" {...{
          "href": "https://www.nerdfonts.com/font-downloads"
        }}>{`CaskaydiaCove NF`}</a>{` `}{`(`}{`A Nerd Font that works with `}<a parentName="li" {...{
          "href": "https://starship.rs/"
        }}>{`Starship`}</a>{`)`}{`.`}</li>
      <li parentName="ul">{`You can find the list of extensions I use with VS Code `}<a parentName="li" {...{
          "href": "https://gist.github.com/Amorpheuz/ccc185be3a3a9d4a51b2b43784853d5b"
        }}>{`here`}</a>{`.`}</li>
    </ul>
    <h2>{`fetchBrowserExtensions`}</h2>
    <p>{`My current browser of choice for browsing is Microsoft Edge, but I prefer Firefox Developer Edition while developing apps.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://adblockplus.org/"
        }}>{`Adblock Plus`}</a>{` - Block Intrusive ads.🚯`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://qlearly.com/"
        }}>{`Qlearly`}</a>{` - Handle bazillion tabs effectively.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.microsoft.com/en-in/microsoft-365/microsoft-editor"
        }}>{`Microsoft Editor`}</a>{` - Handy Dandy writing assistant.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.deque.com/axe/"
        }}>{`Axe`}</a>{` - Check the accessibility of your webpages.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://microsoftedge.microsoft.com/addons/detail/react-developer-tools/gpphkfbcpidddadnkolkpfckpihlkkil"
        }}>{`React Developer Tools`}</a>{` - A must for React Devs!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.eff.org/https-everywhere"
        }}>{`HTTPS Everywhere`}</a>{` - Secure Web Surfing.🏄‍♂️`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://khan.github.io/tota11y/"
        }}>{`Tota11y`}</a>{` - an accessibility (a11y) visualization toolkit!`}</li>
    </ul>
    <h2>{`fetchDesktopApps`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/microsoft/terminal"
        }}>{`Terminal`}</a>{` with `}<a parentName="li" {...{
          "href": "https://starship.rs/"
        }}>{`Starship`}</a>{` -  A modern terminal application for users of command-line tools and shells.`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/PowerShell/PowerShell"
            }}>{`PowerShell`}</a>{` for Windows.`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`zsh`}</inlineCode>{` on Linux & WSL. Here's a handy guide to `}<a parentName="li" {...{
              "href": "https://gist.github.com/derhuerst/12a1558a4b408b3b2b6e"
            }}>{`installing zsh`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ubuntu.com/wsl"
        }}>{`WSL - Ubuntu`}</a>{` - A complete Ubuntu terminal environment with Windows Subsystem for Linux.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.notion.so/product"
        }}>{`Notion`}</a>{` - Awesome note-taking app!✍`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.spotify.com/"
        }}>{`Spotify`}</a>{` - Everyone needs some tunes to jam to.🎶`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://store.steampowered.com/"
        }}>{`Steam`}</a>{` - Casual Gamer, hit me up for Rocket League!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://discordapp.com/"
        }}>{`Discord`}</a>{` - My favorite way to interact with tons of Communities.🔊`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.adobe.com/in/products/xd.html"
        }}>{`Adobe XD`}</a>{` - Powerful Design tool for a variety of Interfaces. `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.adobe.com/in/products/illustrator.html"
        }}>{`Adobe Illustrator`}</a>{` - Powerful Vector graphics software!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/microsoft/PowerToys"
        }}>{`PowerToys`}</a>{` - Get the feels of a power user.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/TranslucentTB/TranslucentTB"
        }}>{`TranslucentTB`}</a>{` - Make your Taskbar transparent.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/File-New-Project/EarTrumpet"
        }}>{`EarTrumpet`}</a>{` - Intuitive Volume Control.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.office.com/"
        }}>{`Microsoft Office`}</a>{` - Cause Office.📂`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.gitkraken.com/"
        }}>{`GitKraken`}</a>{` - Cool Git Client!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.canva.com/"
        }}>{`Canva`}</a>{` - When I need to design something quickly!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://responsively.app/"
        }}>{`ResponsivelyApp`}</a>{` - Awesome Browser which helps develop responsive websites!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.docker.com/products/docker-desktop"
        }}>{`Docker Desktop`}</a>{` - Cause Docker.🐳`}</li>
    </ul>
    <p>{`Inspired by `}<a parentName="p" {...{
        "href": "https://dev.to/nickytonline"
      }}>{`Nick Taylor's`}</a>{` DEV post on `}<a parentName="p" {...{
        "href": "https://dev.to/nickytonline/do-you-have-a-uses-page-5b82"
      }}><inlineCode parentName="a">{`Do you have a "uses" page?`}</inlineCode></a>{`!❤`}</p>
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      